import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout/layout";
import Seo from "../../components/seo";

import "../index.scss";

import ImgLogo from "../../images/logo-tanecni-zlonin.svg";

import "../normalize.css";

import Bloglist from "../../components/bloglist/bloglist";
import Footer from "../../components/footer/footer";

const Article = () => {
  return (
    <Layout>
      <Seo title="Registrace na nový taneční kurz pro dospělé otevřena" />

      <div className="container container--article mb3">
        <Link to="/" data-sal="fade" data-sal-duration="1000">
          <img
            src={ImgLogo}
            alt="Taneční Zlonín logo"
            className="tz-miniheader__logo"
          />
        </Link>

        <h1
          className="tz-miniheader__h1"
          data-sal="fade"
          data-sal-duration="1000"
        >
          Registrace na&nbsp;nový taneční kurz pro&nbsp;dospělé otevřena
        </h1>

        <p data-sal="fade" data-sal-duration="1000">
          29. ledna 2025 ZAČÍNÁME další taneční kurz.
        </p>
        <p data-sal="fade" data-sal-duration="1000">
          Opět každou středu ve&nbsp;velkém společenském sále KC&nbsp;Zlonín
        </p>
        <p data-sal="fade" data-sal-duration="1000">
          Začínáme 29. ledna 2025 kurz v&nbsp;rozsahu 8&nbsp;lekcí - vždy
          ve&nbsp;středu 18:45&nbsp;-&nbsp;20:15.
          <br />
          (29.1., 5.2., 12.2., 19.2., 26.2., 5.3., 12.3., 19.3.)
        </p>
        <p data-sal="fade" data-sal-duration="1000">
          Cena: 4.200 Kč/pár
        </p>

        <p data-sal="fade" data-sal-duration="1000">
          Registrujte se co nejdříve:
        </p>
        <ul data-sal="fade" data-sal-duration="1000">
          <li>
            na emailu{" "}
            <a href="mailto:tanecni.zlonin@gmail.com">
              tanecni.zlonin@gmail.com
            </a>
          </li>
          <li>
            na messengeru stránky{" "}
            <a
              href="https://www.facebook.com/tanecni.zlonin"
              target="_blank"
              rel="noreferrer"
            >
              Taneční&nbsp;Zlonín
            </a>
          </li>
          <li>
            na telefonu/WhatsApp{" "}
            <a href="tel:+420732560838">+420 732 560 838</a>
          </li>
        </ul>

        <p data-sal="fade" data-sal-duration="1000">
          Přijďte si s námi zatančit, těšíme se na&nbsp;Vás!
        </p>
        <p data-sal="fade" data-sal-duration="1000">
          Vaši lektoři
          <br />
          Irina &amp; Jarda
        </p>

        <Link
          to="/tanecni-pro-dospele"
          data-sal="fade"
          data-sal-duration="1000"
          className="tz-button tz-button--small tz-button--inverse"
        >
          Více informací o&nbsp;tanečním kurzu pro&nbsp;dospělé
        </Link>
      </div>

      <Bloglist></Bloglist>
      <Footer></Footer>
    </Layout>
  );
};

export default Article;
